/*
 * This code and all components (c) Copyright 2019-2020, Wowza Media Systems, LLC. All rights reserved.
 * This code is licensed pursuant to the BSD 3-Clause License.
 */
// import { mungeSDPPlay } from 'WowzaMungeSDP.js';
// import WowzaPeerConnectionPlay from 'WowzaPeerConnectionPlay.js';
function wowzawebrtc() {
    this.state = {
        connectionState: 'stopped',
        videoElementPlay: undefined,
        sdpURL: '',
        streamInfo: {
            applicationName: "",
            streamName: "",
            sessionId: "[empty]"
        },
        userData: {param1: "value1"} // ?
    }
    this.wowzaPeerConnectionPlay = undefined;
    this.callbacks = {}; // TODO: turn into listeners
    this.newAPI = false;

    let that = this;

    this.setState = function (newState) {
        let _this = this;
        return new Promise((resolve, reject) => {
            that.state = {...that.state, ...newState};
            if (that.callbacks.onStateChanged != null) {
                that.callbacks.onStateChanged(_this.state);
            }
            resolve(that.state);
        });
    }
    this.getState = function () {
        return that.state;
    }
// Private callbacks for the peerConnection
    this.onconnectionstatechange = function (evt) {
        if (evt.target != null && evt.target.connectionState != null) {
            that.setState({connectionState: evt.target.connectionState});
        }
    }
    this.onstop = function () {
        that.setState({connectionState: 'stopped'});
    }
// External wire callbacks
    this.on = function (_callbacks) {
        that.callbacks = _callbacks;
    }
// External set
    this.set = function (props) {
        let _this = this;
        return new Promise((resolve, reject) => {
            let currentState = that.getState();
            let newStreamInfo = {...currentState.streamInfo};
            let newState = {};
            if (props.videoElementPlay != null)
                newState['videoElementPlay'] = props.videoElementPlay;
            if (props.sdpURL != null)
                newState['sdpURL'] = props.sdpURL.trim();
            if (props.applicationName != null)
                newStreamInfo['applicationName'] = props.applicationName.trim();
            if (props.streamName != null)
                newStreamInfo['streamName'] = props.streamName.trim();
            if (props.sessionId != null)
                newStreamInfo['sessionId'] = props.sessionId;
            if (props.streamInfo != null)
                newStreamInfo = {...newStreamInfo, ...props.streamInfo};
            newState['streamInfo'] = newStreamInfo;
            if (props.userData != null)
                newState['userData'] = {...props.userData};
            this.setState(newState)
                .then((s) => {
                    resolve(s);
                });
        });
    }
    this.getAvailableStreams = function () {
        let currentState = that.getState();
        this.wowzaPeerConnectionPlay = new WowzaPeerConnectionPlay({
            sdpURL: currentState.sdpURL,
            videoElement: currentState.videoElementPlay,
            streamInfo: currentState.streamInfo,
            userData: currentState.userData,
            mungeSDP: mungeSDPPlay,
            onconnectionstatechange: onconnectionstatechange,
            onstop: onstop,
            onerror: errorHandler
        });
        return (that.wowzaPeerConnectionPlay.getAvailableStreams());
    }

    this.play = function () {
        let currentState = that.getState();
        let _this = this;
        this.wowzaPeerConnectionPlay = new WowzaPeerConnectionPlay({
            sdpURL: currentState.sdpURL,
            videoElement: currentState.videoElementPlay,
            streamInfo: currentState.streamInfo,
            userData: currentState.userData,
            mungeSDP: mungeSDPPlay,
            onconnectionstatechange: that.onconnectionstatechange,
            onstop: that.onstop,
            onerror: that.errorHandler
        });
        that.wowzaPeerConnectionPlay.start();
    }
    this.stop = function () {
        that.wowzaPeerConnectionPlay.stop();
        that.wowzaPeerConnectionPlay = undefined;
    }
    this.errorHandler = function (error) {
        console.log('WowzaWebRTCPlay ERROR:');
        console.log(error);
        if (error.message == null) {
            if (error.target != null) {
                console.log('typeof error.target: ' + typeof error.target);
            }
        }
        let newError = {...error}
        if (that.callbacks.onError != null) {
            that.callbacks.onError(error);
        }
    }
    this.WowzaWebRTCPlay = {
        on: that.on,
        set: that.set,
        getState: that.getState,
        getAvailableStreams: that.getAvailableStreams,
        play: that.play,
        stop: that.stop
    }
}

// export default WowzaWebRTCPlay;