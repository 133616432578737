function WebRTCPublisher(translator_item) {

    this.state = {
        publishing: false,
        pendingPublish: false,
        pendingPublishTimeout: undefined,
        muted: true,
        video: false,
        selectedCam: '',
        selectedMic: '',
        settings: {
            sdpURL: "wss://streaming2.rentit.hu/webrtc-session.json",
            applicationName: "webrtc",
            audioBitrate: "64",
            audioCodec: "opus",
            videoBitrate: "3500",
            videoCodec: "42e01f",
            videoFrameRate: "8",
            frameSize: "default"
        }
    };

    this.WowzaWebRTCPublish;
    this.publishing = false;

    this.translator_item = translator_item;

    this.label = translator_item.name[localState.lang];
    this.id = translator_item.stream_name;
    this.translator_id = translator_item.assigned_translator;
    this.translator_lang_id = translator_item.id;
    this.is_main = translator_item.main_lang;
    this.player;
    this.inputSelect;
    this.container;
    this.initialized = false;

    let that = this;

    this.init = function() {
        that.WowzaWebRTCPublish = new wowzawebrtcpublish();

        that.state.settings.streamName = that.id;

        that.WowzaWebRTCPublish.on({
            gotDevices: () => {
                if (!that.publishing) {
                    that.start();
                }
            },
            devicesReady: () => {
                for (let i = 0; i < that.WowzaWebRTCPublish.state.microphones.length; i++) {

                    let el = that.WowzaWebRTCPublish.state.microphones[i];

                    let option = $("<option/>", {
                        value: el.deviceId,
                        text: el.label
                    });

                    option.appendTo(that.inputSelect);
                }

                if (that.translator_item.deviceId != undefined) {
                    that.inputSelect.val(that.translator_item.deviceId);
                    that.changeInput(that.translator_item.deviceId);
                }
            },
            onStateChanged: (newState) => {
                console.log("WowzaWebRTCPublish.onStateChanged");
                console.log(newState);

                if (newState.connectionState === 'connected')
                {
                    that.onPublishPeerConnected();
                }
                else if (newState.connectionState === 'failed')
                {
                    that.onPublishPeerConnectionFailed();
                }
                else
                {
                    that.onPublishPeerConnectionStopped();
                }
            },
            onCameraChanged: (cameraId) => {
                if (cameraId !== that.state.selectedCam)
                {
                    that.state.selectedCam = cameraId;
                    let camSelectValue = 'CameraMobile_'+cameraId;
                    if (cameraId === 'screen') camSelectValue = 'screen_screen';
                }
            },
            onMicrophoneChanged: (microphoneId) => {
                if (microphoneId !== that.state.selectedMic)
                {
                    that.state.selectedMic = microphoneId;
                }
            },
            onError: that.errorHandler
        });

        that.container = $("<div/>").addClass("relay-container");
        let labelDiv = $("<div/>").addClass("translator_label").html(that.label);
        that.player = $("<video/>").attr("id", that.id).attr("muted", "muted").attr("autoplay", "autoplay").attr("controls", true);
        that.inputSelect = $("<select/>").addClass("input-select").attr("data-translator", that.translator_lang_id);

        that.inputSelect.on("change", function() {
            that.changeInput($(this).val());
        });

        labelDiv.appendTo(that.container);
        that.player.appendTo(that.container);
        that.inputSelect.appendTo(that.container);
        that.container.appendTo($("#translator-relay .relay-audios"));

        that.player.get(0).muted = true;

        that.WowzaWebRTCPublish.set({
            videoElementPublish: that.player.get(0),
            useSoundMeter: false,
            videoEnabled: false
        });

        that.initialized = true;
    }

    this.changeInput = function(deviceid) {
        if (that.WowzaWebRTCPublish.getMicrophone() != deviceid) {
            that.WowzaWebRTCPublish.setMicrophone(deviceid);
        }
        that.player.get(0).muted = true;
    }

    this.update = function(settings) {
        that.state.settings = settings;
        return that.WowzaWebRTCPublish.set(settings);
    }

    this.start = function() {
        that.WowzaWebRTCPublish.start();
        that.publishing = true;
    };

    this.stop = function() {
        that.WowzaWebRTCPublish.stop();
        that.publishing = false;
    };

    this.onPublishPeerConnected = function() {
        that.state.publishing = true;
        that.setPendingPublish(false);
    }

    this.onPublishPeerConnectionFailed = function() {
        that.setPendingPublish(false);
        that.onPublishPeerConnected();
        that.errorHandler({message:"Peer connection failed."});
    }

    this.onPublishPeerConnectionStopped = function() {
        if (!that.state.pendingPublish)
        {
            that.state.publishing = false;
        }
    }

    this.setPendingPublish = function(pending) {
        if (pending)
        {
            that.state.pendingPublish = true;
            that.state.pendingPublishTimeout = setTimeout(()=>{
                that.stop();
                that.errorHandler({message:"Publish failed. Unable to connect."});
                that.setPendingPublish(false);
            },10000);
        }
        else
        {
            that.state.pendingPublish = false;
            if (that.state.pendingPublishTimeout != null)
            {
                clearTimeout(that.state.pendingPublishTimeout);
                that.state.pendingPublishTimeout = undefined;
            }
        }
    }

    this.errorHandler = function(error) {
        let message;
        if(error.name == "OverconstrainedError"){
            message = "Your browser or camera does not support this frame size";
            that.updateFrameSize("default");
        } else if ( error.message ) {
            message = error.message;
        }
        else {
            message = error
        }
        console.log(message);
        that.stop();
    }
}
