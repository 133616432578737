/*
 * This code and all components (c) Copyright 2019-2020, Wowza Media Systems, LLC. All rights reserved.
 * This code is licensed pursuant to the BSD 3-Clause License.
 */

function WowzaPeerConnectionPublish() {
    this.browserDetails = window.adapter.browserDetails;
    this.mungeSDP = undefined;
    this.onconnectionstatechange = undefined;
    this.onstop = undefined;
    this.onerror = undefined;

    this.localStream = undefined;
    this.streamInfo = undefined;
    this.mediaInfo = {
            videoBitrate: "",
            audioBitrate: "",
            videoFrameRate: "30",
            videoCodec: "42e01f",
            audioCodec: "opus"
        }
    this.userData = undefined;
    this.statsInterval = undefined;
    this.wsConnection = undefined;
    this.peerConnection = undefined;
    this.peerConnectionConfig = null;
    this.videoSender = undefined;
    this.audioSender = undefined;

    let that = this;

    this.gotIceCandidate = function(event) {
        if (event.candidate != null) {
            console.log('WowzaPeerConnectionPublish.gotIceCandidate: ' + JSON.stringify({'ice': event.candidate}));
        }
    }

    this.gotDescription = function (description) {
        console.log(that);
        console.log("WowzaPeerConnectionPublish.gotDescription: SDP:");
        console.log(description.sdp + '');
        let mungeData = new Object();

        if (that.mediaInfo.audioBitrate != null)
            mungeData.audioBitrate = that.mediaInfo.audioBitrate;
        if (that.mediaInfo.videoBitrate != null)
            mungeData.videoBitrate = that.mediaInfo.videoBitrate;
        if (that.mediaInfo.videoFrameRate != null)
            mungeData.videoFrameRate = that.mediaInfo.videoFrameRate;
        if (that.mediaInfo.videoCodec != null)
            mungeData.videoCodec = that.mediaInfo.videoCodec;
        if (that.mediaInfo.audioCodec != null)
            mungeData.audioCodec = that.mediaInfo.audioCodec;
        if (that.mungeSDP != null) {
            description.sdp = that.mungeSDP(description.sdp, mungeData);
        }
        console.log("WowzaPeerConnectionPublish.gotDescription: Setting local description SDP: ");
        console.log(description.sdp);
        console.log(that.wsConnection);

        that.peerConnection
            .setLocalDescription(description)
            .then(() => that.wsConnection.send('{"direction":"publish", "command":"sendOffer", "streamInfo":' + JSON.stringify(that.streamInfo) + ', "sdp":' + JSON.stringify(description) + ', "userData":' + JSON.stringify(that.userData) + '}'))
            .catch((error) => {
                let newError = {message: "Peer connection failed", ...error};
                that.errorHandler(newError);
            });
    }

    this.createOnStats = function(onStats) {
        return () => {
            if (that.peerConnection != null) {
                that.peerConnection.getStats(null)
                    .then(onStats, err => console.log(err));
            }
        }
    }

    this.wsConnect = function(url) {
        try {
            that.wsConnection = new WebSocket(url);
        } catch (e) {
            that.errorHandler(e);
            return;
        }

        console.log(that.wsConnection);

        that.wsConnection.binaryType = 'arraybuffer';
        that.wsConnection.onopen = function () {
            console.log("WowzaPeerConnectionPublish.wsConnection.onopen");
            that.peerConnection = new RTCPeerConnection(that.peerConnectionConfig);
            that.videoSender = undefined;
            that.audioSender = undefined;
            that.peerConnection.onicecandidate = (event) => {
                if (event.candidate != null) {
                    console.log('WowzaPeerConnectionPublish.gotIceCandidate: ' + JSON.stringify({'ice': event.candidate}));
                }
            }

            that.peerConnection.onconnectionstatechange = (event) => {
                if (that.onconnectionstatechange != null) {
                    that.onconnectionstatechange(event);
                }
            }

            that.peerConnection.onnegotiationneeded = (event) => {
                that.peerConnection.createOffer(that.gotDescription, that.errorHandler);
            }
            let localTracks = that.localStream.getTracks();
            for (let localTrack in localTracks) {
                let sender = that.peerConnection.addTrack(localTracks[localTrack], that.localStream);
                if (localTracks[localTrack].type === 'audio') {
                    that.audioSender = sender;
                } else if (localTracks[localTrack].type === 'video') {
                    that.videoSender = sender;
                }
            }
        }

        that.wsConnection.onmessage = function (evt) {
            console.log("WowzaPeerConnectionPublish.wsConnection.onmessage: " + evt.data);
            var msgJSON = JSON.parse(evt.data);
            var msgStatus = Number(msgJSON['status']);
            var msgCommand = msgJSON['command'];
            if (msgStatus != 200) {
                that.stop();
                that.errorHandler({message: msgJSON['statusDescription']});
            } else {
                var sdpData = msgJSON['sdp'];
                if (sdpData !== undefined) {
                    var mungeData = new Object();
                    if (that.mediaInfo.audioBitrate !== undefined)
                        mungeData.audioBitrate = that.mediaInfo.audioBitrate;
                    if (that.mediaInfo.videoBitrate !== undefined)
                        mungeData.videoBitrate = that.mediaInfo.videoBitrate;

                    console.log("WowzaPeerConnectionPublish.wsConnection.onmessage: Setting remote description SDP:");
                    console.log(sdpData.sdp);

                    that.peerConnection
                        .setRemoteDescription(new RTCSessionDescription(sdpData),
                            () => {
                            },
                            that.errorHandler
                        );
                }
                var iceCandidates = msgJSON['iceCandidates'];
                if (iceCandidates !== undefined) {
                    for (var index in iceCandidates) {
                        console.log('WowzaPeerConnectionPublish.wsConnection.iceCandidates: ' + iceCandidates[index]);
                        that.peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidates[index]));
                    }
                }
            }
        }

        that.wsConnection.onclose = function () {
            console.log("WowzaPeerConnectionPublish.wsConnection.onclose");
        }

        that.wsConnection.onerror = function (error) {
            console.log('wsConnection.onerror');
            console.log(error);
            let message = "Websocket connection failed: " + url;
            console.log(message);
            let newError = {message: message, ...error};
            that.stop();
            that.errorHandler(newError);
        }
    }

    this.replaceTrack = function (type, newTrack) {
        if (that.peerConnection != null) {
            console.log(that.peerConnection);
            if (type === 'audio') {
                if (that.audioSender != null) {
                    that.audioSender.replaceTrack(newTrack);
                } else {
                    that.audioSender = that.peerConnection.addTrack(newTrack);
                }
            } else if (type === 'video') {
                if (that.videoSender != null) {
                    that.videoSender.replaceTrack(newTrack);
                } else {
                    that.videoSender = that.peerConnection.addTrack(newTrack);
                }
            }
        }
    }

// startProps:
//   wsURL: string
//   localStream: MediaStream
//   streamInfo: { applicationName, streamName }
//   mediaInfo: { videoBitrate, audioBitrate, videoFrameRate, videoCodec, audioCodec }
//   userData: any
//   mungeSDP: function (sdpStr, mungeData)
//   onconnectionstatechange: function
//   onerror: function

    this.start = function (props) {
        let wsURL = props.wsURL;
        that.localStream = props.localStream;

        if (props.streamInfo != null)
            that.streamInfo = props.streamInfo;
        if (props.mediaInfo != null)
            that.mediaInfo = props.mediaInfo;
        if (props.userData != null)
            that.userData = props.userData;
        if (props.mungeSDP != null)
            that.mungeSDP = props.mungeSDP;
        if (props.onconnectionstatechange != null)
            that.onconnectionstatechange = props.onconnectionstatechange;
        if (props.onstop != null)
            that.onstop = props.onstop;
        if (props.onerror != null)
            that.onerror = props.onerror;
        if (props.onstats != null) {
            that.statsInterval = setInterval(that.createOnStats(props.onstats), 5000);
        }
        if (that.peerConnection == null) {
            if (that.wsConnection != null)
                that.wsConnection.close();
            that.wsConnection = null;
            console.log("WowzaPeerConnectionPublish.start: wsURL:" + wsURL + " streamInfo:" + JSON.stringify(that.streamInfo));
            that.wsConnect(wsURL);
        } else {
            console.log('WowzaPeerConnectionPublish.start: peerConnection already in use, not starting');
        }
    }

    this.stop = function () {
        if (that.peerConnection != null)
            that.peerConnection.close();
        that.peerConnection = undefined;
        that.videoSender = undefined;
        that.audioSender = undefined;
        if (that.wsConnection != null)
            that.wsConnection.close();
        that.wsConnection = undefined;
        if (that.statsInterval != null) {
            clearInterval(that.statsInterval);
            that.statsInterval = undefined;
        }
        if (that.onstop != null) {
            that.onstop();
        }
    }

    this.isStarted = function() {
        return (that.peerConnection != null);
    }

    this.errorHandler = function(error) {
        console.trace();
        if (that.onerror != null) {
            that.onerror(error);
        }
    }
}